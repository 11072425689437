<template>
  <base-web-page>
    <template v-slot:main>
      <el-main style="padding:30px 20px;margin:0 auto;width:100%;max-width:907px;text-align:left;">
        <h1 style="text-align:center;">Terms of Service</h1>
        <h4>Introduction</h4>
        <p class="p-content">
          These are the Terms and Conditions when accessing and using the website of Ucommune Pte. Ltd. (UEN : 201633328E) (“<strong>Website</strong>”), 
          any applications of Ucommune Pte. Ltd. (“<strong>Apps</strong>”) and the  Services (as defined  below). In  addition,  the  usage  of  the  Website,  
          the  Apps and  the Services is also subject to any posted guidelines or rules. Please read the following Terms and Conditions carefully 
          before using the Website, the Apps or opening an account ("<strong>Account</strong>") so that you are aware of your legal rights and obligations with 
          respect to Ucommune Pte. Ltd. (“<strong>Company</strong>” or “<strong>Ucommune</strong>”).
        </p>
        <p class="p-content">
          All Services (as defined hereafter) provided by the Company on the Website and Apps as well as any Content (as defined hereafter) on the Website and 
          any Apps are also subject to these Terms and Conditions. "<strong>Services</strong>" includes but is not limited to (a) the Website, (b) the Apps, and (c) the services
           provided by the Website and the Apps which includes the making pf purchases using the Website or the Apps. “<strong>Content</strong>” includes but is not limited to 
           all information, linked pages, features, data, text, images, photographs, graphics, music, sounds, video, messages, tags, content, programming, software, 
           applications or other materials made available through the Website, the Apps or its related Services. Any new features added to or augmenting the Services 
           are also subject to these Terms and Conditions.
        </p>
        <p class="p-content">
          BY USING THE SERVICES OR OPENING AN ACCOUNT WITH THE COMPANY ("<strong>ACCOUNT</strong>"), YOU SIGNIFY YOUR IRREVOCABLE  ACCEPTANCE  OF THESE  TERMS AND  CONDITIONS. IF YOU  DO  NOT  
          AGREE  TO  THESE  TERMS  AND  CONDITIONS,  PLEASE  DO  NOT  USE  OUR SERVICES OR ACCESS THE WEBSITE OR THE APPS. IF YOU  ARE YOUNGER THAN 18 YEARS OLD   OR   THE   
          RELEVANT  "AGE   OF   MAJORITY"   WHERE   YOU   LIVE,   YOU   MUST  GET PERMISSION FROM A PARENT OR LEGAL GUARDIAN TO OPEN AN ACCOUNT AND THAT PARENT OR LEGAL GUARDIAN 
          MUST AGREE  TO  THESE  TERMS AND  CONDITIONS. IF YOU DO NOT KNOW WHETHER YOU HAVE REACHED THE "AGE OF MAJORITY" WHERE YOU LIVE, OR DO  NOT UNDERSTAND  THIS SECTION,  
          PLEASE DO  NOT CREATE AN ACCOUNT UNTIL YOU HAVE ASKED YOUR PARENT OR LEGAL GUARDIAN FOR  HELP. IF  YOU  ARE  THE PARENT OR LEGAL GUARDIAN OF A MINOR WHO  IS CREATING AN 
          ACCOUNT, YOU  MUST ACCEPT THESE TERMS AND CONDITIONS ON THE MINOR'S BEHALF AND YOU WILL BE RESPONSIBLE  FOR  ALL  USE  OF  THE  ACCOUNT  OR  SERVICES,  INCLUDING  
          PURCHASES MADE  BY  THE  MINOR,  WHETHER  THE  MINOR'S  ACCOUNT  IS  NOW  OPEN  OR  CREATED LATER AND WHETHER OR NOT THE MINOR IS SUPERVISED BY YOU  DURING  SUCH PURCHASE.
        </p>
        <p class="p-content">
          The Company reserves the right to change, modify, suspend or discontinue any portion of the Website, the Apps and/or the Services at any time. The Company may also impose limits on 
          certain  features  or  restrict  your  access  to  parts  of,  or  the  entire,  Website,  Apps  and/or Services in its sole discretion and without notice or liability.
        </p>
        <p class="p-content">
          The Company reserves the right to refuse to provide you access to the Website, the Apps or Services or to allow you to open an Account for any reason.
        </p>
        <h4>PRIVACY</h4>
        <p class="p-content">
          Prior to opening an Account, please review the Company’ s privacy policy (“<strong>Policy</strong>”) which is found at https://ucommune.shop/pages/privacy- policy to understand how the Company
           collects and uses the information associated with your Account and/or your use of the Services. By using the Services or agreeing to these Terms and Conditions, you consent to the 
           Company's collection, use, disclosure and/or processing of your personal data as described in the Policy.
        </p>
        <p class="p-content">
          Users of the Services who are in possession of another user's personal data (the "<strong>Receiving Party</strong>") must (a) comply with all applicable personal data protection laws; (b) allow the other
           user (the "<strong>Disclosing Party</strong>") to remove himself/herself from the Receiving Party's database; and (iii) allow the Disclosing Party to review what information have been collected about 
           him/her by the Receiving Party.
        </p>
        <h4>ACCOUNTS AND SECURITY</h4>
        <p class="p-content">
          To  use  the  Services,  you  are  required  to  register  an  Account  by  selecting  a  unique  user identification ("<strong>User ID</strong>") and password, and by providing certain personal information. If you 
          select a User ID that the Company, in its sole discretion, finds offensive or inappropriate, the Company has the right to suspend or terminate your Account. You may be able to use your Account to gain 
          access to other products, sites or services to which the Company may have enabled access or with which the Company has tied up or collaborated. The Company has not reviewed,  and  assumes no responsibility
          for  any  third  party  content,  functionality,  security, services, privacy policies, or other practices of those products, sites or services. If you do so, the  Terms and  Conditions for  those 
           products,  sites or  services,  including  their  respective privacy  policies,  if  different  from these  Terms and  Conditions and/or  the  Policy,  may  also apply to your use of those products, 
           sites or services.
        </p>
        <p class="p-content">
          You agree to (a) keep your password confidential and use only your User ID and password when logging in, (b) ensure that you log out from your account at the end of each session on the Website and the
           Apps, (c) immediately notify the Company of any unauthorised use of your User ID and/or password, and (d) ensure that your Account information is accurate and up- to- date. You are fully responsible 
           for all activities that occur under your User ID and Account even if such activities or uses were not committed by you. The Company will not be liable for any loss or damage arising from unauthorised 
           use of your password or your failure to comply with this Section.
        </p>
        <p class="p-content">
          You agree that the Company may for any reason, in its sole discretion and without notice or liability to you or any third party, immediately terminate your Account and your User ID. Grounds for such 
          termination may include, but are not limited to extended periods of inactivity and violation of these Terms and Conditions.
        </p>
        <p class="p-content">
          If your Account is or remains inactive (which means you have not logged into your Account) for more than 6 months, the Company has the right to delete or deactivate your Account.
        </p>
        <p class="p-content">
          You may only use the Services and/or open an Account if your applicable jurisdiction allows you to accept these Terms and Conditions.
        </p>
        <h4>LIMITED LICENSE</h4>
        <p class="p-content">
          The Company grants you a limited license to access and use the Services subject to the terms and conditions of these Terms and Conditions for personal use only. This license does not allow you to make 
          any commercial use or any derivative use of the Services (including without limitation  any  of  its individual  elements or  Content).  All  proprietary  Content,  trademarks, service marks, brand 
          names, logos and other intellectual property displayed in the Website and the Apps are the property of the Company and where applicable, third party proprietors identified in the Website and the Apps.
           No right or licence is granted directly or indirectly to any party accessing the Website and the Apps to use or reproduce any such proprietary Content, trademarks, service marks, brand names, logos 
           and other intellectual property, and no party accessing the Website and the Apps shall claim any right, title or interest therein. By using or accessing the Services you agree to comply with the 
           copyright, trademark, service mark, and all other applicable laws that protect the Services, the Website, the Apps and the Contents. You agree  not  to  copy,  distribute,  republish,  transmit,  
           publicly  display,  publicly perform, modify, adapt, rent, sell, or create derivative works of any portion of the Services, the Website, the Apps or the Contents. You also may not, without our prior 
           written consent, mirror or frame any part or whole of the contents of this Website on any other server or as part of any other site.
        </p>
        <h4>ORDERS, PAYMENTS, PRICING AND COLLECTION</h4>
        <p class="p-content">
        All orders made by you will be assumed to be an offer made by you to purchase the selected product(s) and quantities set out in your order upon these Terms and Conditions. All orders shall be subject to acceptance by the Company. Processing of payment for an order shall not in itself constitute acceptance of the order by the Company, provided that where an order for any product(s) is rejected or cancelled by the Company, any payment made for such order shall be reversed or refunded by the Company. All orders made by you are subject to availability. The Company reserves the right to cancel, amend or reject your order in whole or in part, at any time and without liability or compensation, where any product is damaged or spoilt, or unavailable for any other reason. The Company reserves the right to limit your order or the quantity of a particular product you may order.
</p>
        <p class="p-content">
          The Company endeavours to provide you with accurate and up- to- date pricing, product and promotional information. However, errors may sometimes occur and the Company does not guarantee that the pricing, images, or other product or promotional information provided will be accurate, reliable, current, error- free, updated, or complete. You agree that the Company shall not be liable for any errors in the pricing and product or promotional information listed on the Website, the Apps or through the Services. The price to be paid by you shall be based on the prevailing selling price at the time the Company receives your order, which may or may not  be  correctly  reflected  on  the  Website,  the  Apps or  through  the  Services. If a  product offered by Company is not
          as described, you must return it immediately at the Collection Point (as defined below).
        </p>
        <p class="p-content">
          All product prices are shown in Singapore dollars. Prices of products may change from time to time and any promotional discounts or offers may also be withdrawn at any time without notice.
        </p>
        <p class="p-content">
          Payment for all orders shall be made in Singapore dollars. The price stated for the products excludes all  applicable  taxes and  currency  exchange  settlements,  unless stated  otherwise. You are solely responsible for paying such taxes or other charges. The Company may suspend or cancel your access to the Services if it does not receive full payment from you within the stipulated due date for payment. Suspension or cancellation of the Services for non- payment could result in a loss of access to and use of your Account.
        </p>
        <p class="p-content">
          To pay for the orders, you will be asked to select a payment method before you complete your purchase. You can access and change your billing account information and payment method at any time. You agree to allow the Company to use any updated account information regarding your payment method provided by your issuing bank or the applicable payment network. You agree to keep your billing account information current at all times. Changes that you make to your billing account will not affect charges the Company submits to your billing account before the Company is able to reasonably act on your changes.
        </p>
        <p class="p-content">
          By providing the Company with a payment method, you: (a) represent that you are authorised to use the payment method that you provided and that any payment information you provide is true and accurate; (b) authorise the Company to charge you using your preferred payment method; and (c) authorise the Company to charge you for the products ordered.
        </p>
        <p class="p-content">
          Unless otherwise provided by applicable law or by these Terms and Conditions, all purchases are final and non- refundable.
        </p>
        <p class="p-content">
          The Company does not provide delivery of any products. All products are to be collected at the places designated by the Company ("<strong>Collection Point</strong>"). You are to inspect the products before leaving the Collection Point. Once you have collected the products from the Collection Point, you are deemed to have accepted the products and there will be no return or refund.
        </p>
         <h4>Promotions, Voucher Codes and other Privileges and Program</h4>
         <p class="p-content">
          From time to time the Company may run promotions, contests, surveys, privileges and other program, subject to additional terms and conditions, which can be found on the Website and the Apps and are hereby incorporated by reference into these Terms and Conditions.
         </p>
         <p class="p-content">
          The following terms shall apply to the use of credits, vouchers, coupons and codes (collectively "<strong>Voucher Codes</strong>"):
         </p>
         <ul>
           <li class="p-content">
              promotions, contests, privileges and program are only valid for the time period specified by the Company and while stocks last. No compensation or cash will be offered in the event that a promotion is no longer in stock;
           </li>
           <li class="p-content">
              Voucher Codes may only be redeemed by purchasing certain products from the Company during the applicable validity period;
            </li>
           <li class="p-content">
              each Voucher Code is only eligible for a single use and is only valid for the time period specified by the Company;
            </li>
           <li class="p-content">
              Voucher Codes cannot be used in conjunction with any other promotion, voucher, coupon, code or offer, including but not limited to discounted sale items, unless otherwise stated;
            </li>
           <li class="p-content">
              use of any Voucher Code must be indicated at the time of checkout, and all information required by the Company must be provided. No retroactive use of the Voucher Code is permitted;
          </li>
           <li class="p-content">
              Voucher Codes are non- replaceable if lost, stolen, destroyed, duplicated, tampered with or otherwise misappropriated or fraudulently used;
            </li>
            <li class="p-content">
              a minimum spend may be required before use of the Voucher Code is permitted;
            </li>
           <li class="p-content">
            resale, transfer and sharing of Voucher Codes are strictly prohibited;
            </li>
           <li class="p-content">
            Voucher Codes cannot be refunded, redeemed or exchanged for cash;
            </li>
            <li class="p-content">
            the Company reserves the right to vary or impose such other terms and conditions, as it deems appropriate from time to time by posting the varied terms and conditions on the
            Website and/or the Apps;
            </li>
           <li class="p-content">
            the Company reserves the right to verify the validity of any Voucher Code and to declare null and void and charge full price for items purchased, any Voucher Code that in its opinion has been stolen, 
            duplicated, tampered with, or which is suspected to have been misappropriated or fraudulently obtained or used;
            </li>
            <li class="p-content">
              where any free gift is offered as part of a promotion, the free gift is offered on a “ first come first serve” basis and while stocks last basis. No cash or credit will be offered in lieu of the gift;
            </li>
            <li class="p-content">
              where you are permitted to return any product to the Company for a refund/credit and such product was originally purchased under a promotion or discount, you will only be refunded the amount that
               you originally paid for such product. Where the promotion or discount had applied to the entire order, the Company will refund you the selling price of the returned product less the promotion or
                discount applied to the returned product on a pro- rata basis. Further, any free promotional gift given with an order must also be returned if you are returning the product(s) to which the gift 
                related.
            </li>
            <li class="p-content">
              the Company reserves the right to discontinue any promotion, discount, Voucher Code programme, contest, privileges or programme at any time without notice or liability; and
            </li>
           <li class="p-content">
            the Company's decision on all matters relating to promotions, discounts, Voucher Codes and other privileges is final and binding.
           </li>
         </ul>
         <h4>RETURNS AND REFUNDS</h4>
         <p class="p-content">
         You may only apply for the refund and/or return of any product in the following circumstances:
         </p>
         <ul>
           <li class="p-content">
            the product was not available for collection by you;
           </li>
           <li class="p-content">
            the product was defective and/or damaged upon inspection and you return it immediately at the Collection Point;
           </li>
           <li class="p-content">
            upon inspection, the product does not match the agreed specification and you return it immediately at the Collection Point; or
           </li>
           <li class="p-content">
            upon inspection, the product is materially different from the description provided in the listing of the product and you return it immediately at the Collection Point.
           </li>
         </ul>
         <p class="p-content">
          Your application must be submitted via u- shop@ucommune.com.sg.
         </p>
         <p class="p-content">
          The approval of your request for refund and return will be made by the Company in its sole discretion. You acknowledge and agree that the Company’ s decision is final, conclusive and binding.
         </p>
         <p class="p-content">
          To enjoy a hassle- free experience when returning the product, you should ensure that the product, including any complimentary items such as accessories that come with the product, must be returned at the Collection Point in the condition received by you.
         </p>
         <h4>DISCLAIMER</h4>
         <p class="p-content">
           THE SERVICES AND PRODUCTS ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY THE COMPANY OF ANY KIND  EITHER EXPRESS, IMPLIED   OR   STATUTORY   WITH   RESPECT   
           TO   THE   SERVICES   AND   THE   PRODUCTS, INCLUDING   WITHOUT  LIMITATION   WARRANTIES  OF  QUALITY,   PERFORMANCE,   NON- INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, 
           NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE OR  TRADE  USAGE.  WITHOUT  LIMITING  THE  FOREGOING,  THE  COMPANY  DOES  NOT WARRANT THAT THE SERVICES, THIS WEBSITE, 
           THE APPS OR THE FUNCTIONS CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR- FREE, THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THIS WEBSITE,  
           THE  APPS  AND/OR  THE  SERVER  ARE  FREE  OF  VIRUSES,  CLOCKS,  TIMERS, COUNTERS,   WORMS,   SOFTWARE   LOCKS,   DROP   DEAD   DEVICES,   TROJAN- HORSES, ROUTINGS, TRAP DOORS, TIME BOMBS OR ANY OTHER HARMFUL
            CODES, INSTRUCTIONS, PROGRAMS OR COMPONENTS.
         </p>
         <p class="p-content">
           YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF  THE  WEBSITE,  THE  APPS  AND/OR  THE  SERVICES  REMAINS  WITH  YOU   TO   THE MAXIMUM EXTENT PERMITTED BY LAW.
         </p>
         <p class="p-content">
           Some  jurisdictions do  not  allow  the  disclaimer  of implied  warranties,  so  some or  all of the foregoing disclaimers may not apply to you.
         </p>
         <h4>EXCLUSIONS AND LIMITATION OF LIABILITY</h4>
         <p class="p-content">
           IN  NO  EVENT  SHALL  THE  COMPANY  BE  LIABLE  WHETHER  IN  CONTRACT,  WARRANTY, TORT  (INCLUDING  WITHOUT  LIMITATION  NEGLIGENCE  (WHETHER  ACTIVE,  PASSIVE  OR IMPUTED),  PRODUCT  LIABILITY  OR 
            STRICT  LIABILITY  OR  OTHER  THEORY),  OR  OTHER CAUSE OF ACTION AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE, FOR LOSS OF USE, PROFITS,  REVENUES,  GOODWILL  OR  ANTICIPATED  SAVINGS  OR  FOR  ANY  
            INDIRECT, INCIDENTAL,  SPECIAL OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION ANY  LOSS  OF  DATA,  SERVICE  INTERRUPTION,  COMPUTER,  MOBILE  PHONE  OR  MOBILE DEVICE FAILURE) ARISING OUT OF
             OR IN CONNECTION WITH THE USE OR INABILITY TO USE THIS WEBSITE, THE APPS OR THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING THEREFROM, EVEN IF THE COMPANY HAS BEEN ADVISED  OF THE
              POSSIBILITY OF SUCH DAMAGES.
         </p>
         <p class="p-content">
           YOUR ONLY RIGHT WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO TERMINATE YOUR ACCOUNT AND DISCONTINUE ANY  USE  OF  THE SERVICES.
         </p>
         <p class="p-content">
           IF, NOTWITHSTANDING THE PREVIOUS SECTIONS, THE COMPANY IS FOUND BY A COURT OF COMPETENT JURISDICTION TO  BE LIABLE (INCLUDING FOR GROSS NEGLIGENCE),  ITS LIABILITY TO YOU OR TO ANY THIRD PARTY IS 
           LIMITED TO THE LESSER OF SG $100 (ONE HUNDRED SINGAPORE DOLLARS).
         </p>
         <p class="p-content">
           NOTHING IN THESE TERMS AND CONDITIONS SHALL  LIMIT OR  EXCLUDE  ANY  LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY THE COMPANY'S NEGLIGENCE, FOR FRAUD OR FOR ANY OTHER LIABILITY THAT CANNOT BE 
           LAWFULLY LIMITED AND/OR EXCLUDED.
         </p>
         <h4>LINKS TO THIRD PARTY SITES</h4>
         <p class="p-content">
           The links provided throughout the Website will let you leave the Website. These links are provided as a courtesy only, and the sites they link to are not under the control of the Company in any
            manner whatsoever and you therefore access them at your own risk. Therefore, the Company is in no manner responsible for the contents of any such linked site or any link contained within a linked 
            site, including any changes or updates to such sites. The Company is providing these links merely as a convenience, and the inclusion of any link does not in any way imply or express affiliation,
             endorsement or sponsorship by the Company of any linked site and/or any of its content therein.
         </p>
         <h4>TERM OF USE</h4>
         <p class="p-content">
           The license for use of the Website, the Apps and the Services is effective until terminated. This license will terminate as set forth within these Terms and Conditions or if you fail to comply with any 
           term or condition of these Terms and Conditions. In such event, no notice shall be required by the Company to effect such termination.
         </p>
         <h4>THIRD PARTY CONTRIBUTIONS TO THE SERVICES AND EXTERNAL LINKS</h4>
          <p class="p-content">
            Each  contributor  to  the  Services of  data,  text,  images,  sounds,  video,  software  and  other Content is solely responsible for the accuracy, reliability, nature, rights clearance, compliance
             with  law  and  legal  restrictions  associated  with  their  Content  contribution.  As  such,  the Company  is not  responsible to,  and  shall not,  regularly  monitor  or  check for  the  accuracy, 
             reliability, nature, rights clearance, compliance with law and legal restrictions associated with any contribution of Content.
          </p>
           <p class="p-content">
              In addition, the Services may contain links to third party text and video feeds (and podcasts) (collectively,  "<strong>third  party  feeds</strong>"),  products,  sites,  services and  offers,  or
                links to  download third party software applications. Additionally, third parties may make available, on their own sites, third party feeds, and software applications. These third party links, 
                third party feeds, products,  sites,  services  and  software  applications  are  not  owned  or  controlled  by  the Company. Rather, they are operated by, and are the property of, the respective
                 third parties, and may be protected by applicable copyright or other intellectual property laws and treaties. The Company has not reviewed, and assumes no responsibility for the content,
                  functionality, security,   services,   privacy   policies,   or  other  practices  of  these  third   parties.  You  are encouraged to read the terms and other policies published by such third 
                  parties on their sites or otherwise. By  using the Services, you agree that the Company  shall not  be liable in any manner due to your use of, or inability to use, any third- party feed, site 
                  or widget. You further acknowledge and agree that the Company may disable your use of, or remove, any third party links, third party feeds, or applications on the Services to the extent they 
                  violate these Terms and Conditions.
           </p>
           <h4>YOUR REPRESENTATIONS AND WARRANTIES</h4>
           <p class="p-content">
             You represent and warrant that:
           </p>
           <ul>
              <li class="p-content">             
                you possess the legal capacity (and in the case of a minor, valid parent or legal guardian consent), right and ability to enter into these Terms and Conditions and to comply with its terms;
              </li>
              <li class="p-content">
                you will use the Services for lawful purposes only and in accordance with these Terms and Conditions and all applicable laws, rules, codes, directives, guidelines, policies and regulations; and
              </li>
              <li class="p-content">
                you will only use the Services on a device on which such user is authorised by the device's owner.
              </li>
           </ul>
           <h4>INDEMNITY</h4>
           <p class="p-content">
             You  agree  to  indemnify,  defend  and  hold  harmless  the  Company,  its  shareholders,  its subsidiaries,   affiliates,   officers,   agents,   co- branders  or   other  partners,   and   
             employees (collectively, the "<strong>Indemnified Parties</strong>"), at your expense, from and against any and all claims, actions, proceedings, and suits and all related liabilities, damages, settlements, 
             penalties, fines, costs and  expenses (including,  without  limitation,  reasonable  legal  fees and  other  dispute resolution expenses) incurred by any Indemnified Party arising out of or 
             relating to (a) the hosting, operation, management and/or administration of the Services by or on behalf of the Company,  (b) your  violation  or  breach  of any  term of these  Terms and 
              Conditions or  any policy or guidelines, or (c) your use or misuse of the Services, or (e) your breach of any law or the rights of a third party.
           </p>
           <h4>SEVERABILITY</h4>
           <p class="p-content">
             If any provision of these Terms and Conditions shall be deemed unlawful, void, or for any reason unenforceable under the law of any jurisdiction, then that provision shall be deemed severable from 
             these terms and conditions and shall not affect the validity and enforceability of any remaining provisions in such jurisdiction nor the validity and enforceability of the provision in question 
             under the law of any other jurisdiction.
           </p>
           <h4>GOVERNING LAW</h4>
           <p class="p-content">
             These Terms and Conditions shall be governed by and construed in accordance with the laws of  the  Republic  of  Singapore.  Any  dispute,  controversy,  claim  or  difference  of  any 
              kind whatsoever shall arising out of or relating to these Terms and Conditions against or relating to the Company or any Indemnified Party under these Terms and Conditions shall be referred to 
              and finally resolved by arbitration in Singapore in accordance with the Arbitration Rules of the  Singapore  International  Arbitration  Centre  ("<strong>SIAC  Rules</strong>")  for  the  time  being  in  force,
               which rules are deemed to be incorporated by reference in this Section. There will be one (1) arbitrator and the language of the arbitration shall be English.
           </p>
           <h4>GENERAL PROVISIONS</h4>
           <p class="p-content">
             The Company reserves all rights not expressly granted herein.
             </p>
           <p class="p-content">
              The Company may modify these Terms and Conditions at any time by posting the revised Terms and Conditions on the Website and/or the Apps. Your continued use of the Website and/or the Apps after such changes have been posted shall constitute your acceptance of such revised Terms and Conditions.
          </p>
           <p class="p-content">
              You  may  not  assign,  sublicense  or  transfer  any  rights  granted  to  you  hereunder  or subcontract any of your obligations.
          </p>
           <p class="p-content">
              Nothing  in  these  Terms  and  Conditions  shall  constitute  a  partnership,  joint  venture  or principal- agent  relationship  between  you  and  the  Company,  nor  does it  authorise  you  to 
              incur any costs or liabilities on the Company’ s behalf.
            </p>
           <p class="p-content">
              The failure of the Company at any time or times to require performance of any provision hereof shall in no manner affect its right at a later time to enforce the same unless the same is waived in 
              writing.
            </p>
           <p class="p-content">
              These Terms and Conditions are solely for your and the Company’ s benefit and are not for the benefit of any other person or entity, except for the Company and its affiliates and subsidiaries.
            </p>
           <p class="p-content">
              The terms set forth in these Terms and Conditions and any agreements and policies included or referred to in these Terms and Conditions constitute the entire agreement and understanding  of the  
              parties with  respect  to  the  Services,  the  Website  and  the  Apps and supersede any previous agreement or understanding between the parties in relation to such subject matter. The parties also
               hereby exclude all implied terms. In entering into the agreement  formed  by  these  Terms  and  Conditions,  the  parties  have  not  relied  on  any statement,  representation,  warranty, 
                understanding,  undertaking,  promise  or  assurance  of any person other than as expressly set out in these Terms and Conditions. Each party irrevocably  and  unconditionally  waives  all  claims, 
                 rights  and  remedies  which  but  for  this Section it might otherwise have had in relation to any of the foregoing. These Terms and Conditions may  not  be  contradicted,  explained  or  
                 supplemented  by  evidence  of any  prior agreement, any contemporaneous oral agreement or any consistent additional terms.
            </p>
           <p class="p-content">
              If you have any questions or concerns about these Terms and Conditions or any issues raised
              in   these   Terms   and   Conditions,   on   the   Website   or   the   Apps,   please   contacts   us at: enxi.liu@ucommune.com.sg.
            </p>
           <p class="p-content">
             I HAVE READ THESE TERMS AND CONDITIONS AND AGREE TO ALL OF THE PROVISIONS CONTAINED ABOVE AND ANY REVISION THE SAME HEREAFTER. BY CLICKING THE "REGISTER NOW" BUTTON OR ANY SIMILAR ACTION BELOW, 
             I UNDERSTAND THAT I AM CREATING A DIGITAL SIGNATURE, WHICH I INTEND TO HAVE THE SAME FORCE AND EFFECT AS IF I HAD SIGNED MY NAME MANUALLY.
           </p>
      </el-main>
    </template>
  </base-web-page>
</template>

<script>
import BaseWebPage from '../../components/base/BaseWebPage.vue'

export default {
    name: 'TermsOfService',
    components: {
        BaseWebPage
    }
}
</script>

<style>
.p-content{
    font-size: 15px;
    line-height: 20px;
}
</style>